<template>
  <div class="wrapper">
    <b-overlay :show="isLoading" rounded="sm" @shown="onShown">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template>
            <b-card sub-title="Filter">
              <b-row>
                <b-col cols="5">
                  <b-form-group
                    label="Business Unit"
                    label-for="business-select"
                    label-cols="4"
                    label-size="sm"
                    v-slot="{ ariaDescribedby }"
                  >
                    <v-select
                      id="business-select"
                      v-model="filters.business_unit"
                      :options="businessOpt"
                      size="sm"
                      label="name"
                      :reduce="(i) => i.code"
                      :aria-describedby="ariaDescribedby"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                </b-col>
                <b-col cols="5">
                  <b-form-group
                    label="Search"
                    label-for="search"
                    label-cols="2"
                    label-size="sm"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="inputSearch"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        trim
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Search</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>{{ DashName }}</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    v-on:click="show = !show"
                    >Add</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
          <b-table
            responsive
            head-variant="dark"
            stickyColumn
            striped
            hover
            small
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterReminder"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
          >
             <template #cell(department)="row">
                  <span v-if="row.value !== null" @click="departmentPIC(row)"
                  style="color:blue; text-decoration: underline; cursor: pointer;"
                  >{{row.value}}</span>
              </template>

            <template v-slot:cell(actions)="{ item }">
              <b-button
              style="margin-right:5px;"
                title="Delete"
                size="xs"
                variant="outline-danger"
                v-on:click="deleteData(item.id)"
              >
                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            Showing {{ pageItems }} of {{ items.length }} entries
            <b-pagination
              v-model="paging.currentPage"
              :total-rows="rows"
              :per-page="paging.perPage"
              align="right"
            >
            </b-pagination>
          </div>
          <template>
            <b-modal
              v-model="show"
              ref="my-modal"
              hide-header-close
              header-bg-variant="secondary"
              footer-bg-variant="secondary"
              hide-backdrop
              no-close-on-backdrop
              size="md"
              title="Form Business Unit"
            >
              <div id="modal">
                <!-- <form-permit-type ref="permitForm"  /> -->
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                      <b-col cols="12">
                        <!-- BusinessUnit -->
                        <validation-provider
                          name="Business Unit"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Business Unit<code>*</code>
                            </template>
                            <b-form-select
                              :options="businessOpt"
                              value-field="code"
                              text-field="name"
                              id="BusinessUnit"
                              v-model="form.business_unit"
                              :state="getValidationState(validationContext)"
                              trim
                            >
                            </b-form-select>
                            <b-form-invalid-feedback id="BusinessUnit">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /BusinessUnit -->

                        <!-- Department -->
                        <validation-provider
                          name="Department"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Department<code>*</code>
                            </template>
                            <b-form-input

                              id="inputDepartment"
                              v-model="form.department"
                              :state="getValidationState(validationContext)"
                              trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="inputDepartment">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Department -->

                      </b-col>
                    </b-row>
                    <b-row class="float-right">
                      <b-button class="mr-3" variant="success" type="submit"
                        >Save</b-button
                      >
                      <b-button class="mr-3" variant="warning" @click="cancel()"
                        >Cancel</b-button
                      >
                    </b-row>
                  </b-form>
                </validation-observer>
              </div>
              <template #modal-footer>
                <div class="h-300 bg-secondary"></div>
              </template>
            </b-modal>
          </template>
        </b-card>
      </b-container>
      <template #overlay>
        <div ref="loadings" class="text-center">
          <b-icon
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { fnGET, fnPOST } from "../../../../../api/api-methods";
import { APIPATH_DMS, APIPATH_BBIAPPS } from "../../../../../api/api-paths";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
const now = new Date();
export default {
  data() {
    return {
      DashName: "List Department",
      formName: "Department",
      modulName: "Dashboard",
      items: [],
      fields: [
        { key: "actions", label: "actions" },
        { key: "business_unit", label: "business unit" },
        { key: "department", label: "department" },
        { key: "created_by", label: "Created By" },
        {
          key: "created_at",
          label: "Created At",
          formatter: this.setDateFormat,
        },
        { key: "update_by", label: "Update By" },
        {
          key: "update_at",
          label: "Update At",
          formatter: this.setDateFormat,
        },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: false,
      show: false,
      form: {
        idData:null,
        business_unit: null,
        department: null,
        created_by: null,
        update_by: null,
        is_active:null,
        category_submit:null,
      },

      businessOpt: [],
      levelOpt: [],
      locationOpt: [],
      reminderOpt: [],
      max: now,
      expState: true,
      filters: {
        business_unit: "",
        department: "",
        created_by: "",
        update_by: "",
      },
      filter: null,
      includeFiled: ["department",  "created_by", "update_by"],
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.items) {
        return this.filterReminder.length;
      }
    },
    filterReminder() {
      var department = this.filters.department;
      var business_unit = this.filters.business_unit;
      var created_by = this.filters.created_by;
      var update_by = this.filters.update_by;

      return this.items.filter(function (item) {
        var filtereds = true;
        if (department && department.length > 0) {
          filtereds = item.name === department;
        }
        if (filtereds) {
          if (business_unit && business_unit.length > 0) {
            filtereds = item.business_unit === business_unit;
          }
        }
        if (filtereds) {
          if (update_by && update_by.length > 0) {
            filtereds = item.update_by === update_by;
          }
        }
        if (filtereds) {
          if (created_by && created_by.length > 0) {
            filtereds = item.created_by === created_by;
          }
        }
        // if (filtereds) {
        //   if (status === false) {
        //     filtereds = item.is_active === false;
        //   } else {
        //     filtereds = item.is_active === true;
        //   }
        // }
        return filtereds;
      });
    },
    pageItems() {
      var totalPage = Math.ceil(this.rows / this.paging.perPage);
      if (this.paging.currentPage === totalPage) {
        return (
          (this.rows % this.paging.perPage) +
          this.paging.perPage * totalPage -
          this.paging.perPage
        );
      } else {
        return this.paging.perPage * this.paging.currentPage;
      }
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    setDateFormat(value) {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    LoadData() {
      this.form.category_submit = 'get'

      Vue.set(this, "isLoading", true);

      fnPOST(APIPATH_DMS.DepartmentMapping, this.form)
      .then((rsp) => {
        // console.log(rsp.data.payload)
        this.items = rsp.data.payload
        Vue.set(this, "isLoading", false);
      })
      .catch((err) => {
        this.showNotification({
          type: "error",
          message: err,
        });
        Vue.set(this, "isLoading", false);

      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getBusinessUnit() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "businessOpt", rsp.data.payload);
          Vue.set(this.form, "business_unit", rsp.data.payload[0].code);
        })
        .catch((err) => {
          return err;
        });
    },
    cancel() {
      this.$refs["my-modal"].hide();
      this.form.department = null;
      this.form.business_unit = null;
      this.form.created_by = null;
      this.form.update_by = null;
      this.form.idData = null;
      this.form.is_active = null;
      this.form.category_submit = null;

    },
    departmentPIC(row) {
      this.$router.push({ name: 'DepartmentPIC', params: {
        idData:row.item.id,
        business_unit:row.item.business_unit,
        department:row.item.department }
        })
    },
    onSubmit() {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Save",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.form.category_submit = 'created'
            this.form.created_by = this.userData.fullname

            if (this.form.business_unit == null || this.form.business_unit == "") {
              return false;
            }
            if (this.form.department == null || this.form.department == "") {
              return false;
            }
            this.form.department = this.form.department.toUpperCase();

            Vue.set(this, "isLoading", true);

              fnPOST(APIPATH_DMS.DepartmentMapping, this.form)
                .then((rsp) => {
                  this.showNotification({
                    type: "success",
                    message: "Successfully Submitted",
                  });
                  Vue.set(this, "isLoading", false);
                  this.cancel();
                  this.LoadData()
                })
                .catch((err) => {
                  // console.log(err.response.data.message)
                  this.showNotification({
                    type: "error",
                    message: err.response.data.message,
                  });
                  Vue.set(this, "isLoading", false);
                  return err;
                });
            }
        })
    },

    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.set(this, "isLoading", true);
            this.form.category_submit = 'update'
            this.form.update_by = this.userData.fullname
            this.form.idData = id

            fnPOST(APIPATH_DMS.DepartmentMapping, this.form)
            .then((rsp)=> {
              this.showNotification({
                type: "success",
                message: "Successfully Updated",
              });
              Vue.set(this, "isLoading", false);
              this.cancel();
              this.LoadData()
            })
            .catch((err)=> {
              this.showNotification({
                type: "error",
                message: err,
              });
              Vue.set(this, "isLoading", false);

            })

          }
        });
    },
    setUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
      Vue.set(this.form, "update_by", this.userData.fullname);
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      this.$refs.loadings.focus();
    },

  },
  created() {
    this.setUserData();
    this.getBusinessUnit();
    this.LoadData()
  },
};
</script>